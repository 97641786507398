<template>
    <div class="w-full h-full flex flex-col items-center md:justify-center bg-beige overflow-auto">
        
        <div 
            class="flex flex-col md:flex-row m-8 md:rounded-lg md:overflow-hidden">
            
            <div class="w-full md:w-64 bg-gray-darker text-white p-8 pl-12 flex flex-col rounded-t-lg md:rounded-none">
                <div class="md:h-32">
                    <img 
                        src="/assets/logo.svg" 
                        class=" my-4"
                    />
                </div>
                
                <h2>
                    Innlogging
                </h2>
                <p class="text-gray-lightest">
                    Skriv inn e-postadresse og passord. Har du glemt passordet, kan du få nytt ved å klikke "Glemt passord".
                </p>
            </div>
            <div 
                class="w-full md:w-auto flex-grow bg-white p-8 md:px-12 h-full flex flex-col rounded-b-lg md:rounded-none"
                v-if="ready">
                
                <form 
                    class="w-full mb-1" 
                    @submit.prevent="signIn()">
                    
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        <!-- <h2 class="text-white">Error</h2> -->
                        {{error}}
                    </div>
                    
                    <InputSingleline 
                        v-model="email"
                        label="E-Post"
                        name="email"
                        placeholder="john@example.com"
                        class="w-full -mt-4"
                        type="email"
                        :required="true"
                        :autofocus="true"
                    />
                    
                    <InputSingleline 
                        v-model="password"
                        label="Passord"
                        name="password"
                        class="w-full "
                        type="password"
                        :required="true"
                    />
                    
					<div 
						v-if="showAcceptedLegal"
						class="mt-8 mb-4">
						
						<input 
							type="checkbox" 
							v-model="hasAcceptedLegal"
						/>
						Jeg har lest og aksepterer <a class="link" @click.stop="showLegalDocumentNavigation = !showLegalDocumentNavigation">vilkårene for bruk av Upsell</a>
					</div>
					
					<div 
						v-if="showLegalDocumentNavigation"
						class="py-2">
						
						<ul class="space-y-2">
							<li>
								<a 
									href="https://docs.google.com/document/d/1DCfx9KNvvEdrWbN1fLAtKNXJc1ZHbnDTJVEpA7ZeKxE/edit?usp=sharing"
									target="_blank"
									class="link">
									
									UpSell Terms and Conditions
								</a>
							</li>
							<li>
								<a 
									href="https://docs.google.com/document/d/1P3uRxaxbIx7FA5o2jj8VjAY8cmlMWn5ozwTTomcSjJQ/edit?usp=sharing"
									target="_blank"
									class="link">
									
									UpSell Customer Agreement
								</a>
							</li>
							<li>
								<a 
									href="https://docs.google.com/document/d/1WadRhTcMlsIq8Kp1F14EqZDLavSuMIzuZOBgoXfiKsE/edit?usp=share_link"
									target="_blank"
									class="link">
									
									The legal aspects of electronic signatures
								</a>
							</li>
						</ul>
					</div>
					
                    <button 
                        type="submit" 
                        class="button submit my-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        Logg på
                    </button>
                </form>
                
                <div class="text-center flex flex-col">
                    <router-link 
                        :to="{ name: 'resetpassword'}"
                        class="mb-2">
                        
                        Har du glemt passordet ditt?
                    </router-link>
                    
                    <router-link :to="{ name: 'signup'}">
                        Har du ikke registrert bruker ennå?
                    </router-link>
                    
                    <hr class="border-t border-dashed border-beige-dark mt-6 mb-6">
                    
                    <router-link :to="{ name: 'portal-signin'}">
                        Innlogging for fellesbruker
                    </router-link>
                </div>
            </div>
        </div>
        
        <router-link 
            :to="{ name: 'www'}"
            class="mb-8">
            <!-- backToWebsite -->
            Tilbake til websidene
        </router-link>
        
    </div>
</template>

<script>
    import AuthMixins from '@/mixins/AuthMixins';
    
    export default {
        mixins: [
            AuthMixins,
        ],
        
		watch: {
			hasAcceptedLegal( newVal ){
				localStorage.hasAcceptedLegal = JSON.stringify( newVal );
			},
		},
		
        data() {
            return {
                ready: false,
                email: null,
                password: null,
				hasAcceptedLegal: false,
				showAcceptedLegal: true,
				showLegalDocumentNavigation: false,
                error: null,
                loading: false,
            }
        },
        
        async created() {
            const signedInStatus = await this.checkUser();
            
            if (signedInStatus) {
                this.$router.push({name: 'accounts'});
            }
            else {
                this.ready = true;
            }
			
			if ( !localStorage.hasAcceptedLegal ) {
				this.showAcceptedLegal = true;
			}
        },
    }
</script>
